
















































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";
import { Seminar, SimpleUser } from "@/core/models";
import api from "@/core/utils/api";

@Component
export default class SeminarScores extends Vue {
  @Getter("seminars/selected") selected?: Seminar;
  @Getter("seminars/users") users?: SimpleUser[];
  @Getter("profile/id") userId!: string;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  gettingScores = false;
  elements: any[] = [];
  async getScores() {
    const id = this.selected?.id;
    if (!id) return;

    this.gettingScores = true;
    try {
      const res = (await api.get(`/api/Seminar/Scores/${id}`)) as any;
      if (res.elements?.length > 0) this.elements = res.elements;
    } catch (error) {
      console.log(error);
      this.displaySnackbar("Could not fetch scores");
    }
    this.gettingScores = false;
  }

  @Watch("selected", { immediate: true })
  seminarChanged() {
    this.getScores();
  }
}
